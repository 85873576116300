var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"header"}},[_c('el-card',{attrs:{"shadow":"always"}},[_c('div',[_c('el-button',{directives:[{name:"throttle",rawName:"v-throttle"}],attrs:{"type":"primary","size":"medium"},on:{"click":_vm.goBack}},[_vm._v("返回")])],1),_c('div',{attrs:{"id":"exam-box"}},[_vm._v(_vm._s(_vm.urlParam.examName))]),_c('div',{attrs:{"id":"place-box"}},[_vm._v(" 考点："+_vm._s(_vm.urlParam.sysOrgExamPlaceName)+" "),(
          _vm.currBatchSessionInfo.callBatchPlatformHistorySessionExamineeList &&
          _vm.currBatchSessionInfo.callBatchPlatformHistorySessionExamineeList
            .length > 0
        )?_c('span',[_vm._v("批次："+_vm._s(_vm.currBatchSessionInfo.examSession > 9 ? _vm.currBatchSessionInfo.examSession : '0' + _vm.currBatchSessionInfo.examSession))]):_vm._e()]),_c('div',{staticStyle:{"margin-top":"10px","line-height":"36px"}},[_c('el-row',[_c('span',[_vm._v(" 统计：当前已完成考试"+_vm._s(_vm.censusInfo.haveExamExamineeCount)+"人， 未完成考试"+_vm._s(_vm.censusInfo.notExamExamineeCount)+"人， 共"+_vm._s(_vm.censusInfo.examExamineeCount)+"人 ")]),(_vm.currBatchSessionInfo.sessionFinishTime)?_c('span',{staticStyle:{"margin-left":"30px"}},[_vm._v("确定批次时间："+_vm._s(_vm.formatDateTime( new Date(_vm.currBatchSessionInfo.sessionFinishTime), 'yyyy-MM-dd hh:mm:ss' )))]):_vm._e()]),_c('el-row',{staticStyle:{"margin-top":"10px"}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"userTable",staticStyle:{"width":"100%"},attrs:{"stripe":"","border":"","data":_vm.tableData,"tooltip-effect":"dark","header-cell-style":{
            'text-align': 'center',
            backgroundColor: '#DFE6EC',
          },"cell-style":{ 'text-align': 'center' }}},[_c('el-table-column',{attrs:{"prop":"index","label":"序号","width":"60"}}),_c('el-table-column',{attrs:{"prop":"examineeCode","label":"考生编号","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"examineeName","label":"考生姓名","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"examineeSchoolName","label":"考生学校","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"examineeSchoolClassName","label":"考生班级","show-overflow-tooltip":""}}),(_vm.urlParam.randomTimingType == 1)?_c('el-table-column',{attrs:{"prop":"sysOrgExamPlaceRoomName","label":"所在考场","show-overflow-tooltip":""}}):_vm._e(),(_vm.urlParam.randomTimingType == 2)?_c('el-table-column',{attrs:{"prop":"sysOrgExamPlaceRoomNameList","label":"所在考场","show-overflow-tooltip":"","formatter":_vm.customFormatter}}):_vm._e(),(_vm.randomTimingDrawType == 1)?_c('el-table-column',{attrs:{"prop":"seatCode","label":"座位号","show-overflow-tooltip":""}}):_vm._e()],1),_c('div',{attrs:{"id":"pagination-box"}},[_c('el-pagination',{attrs:{"current-page":_vm.currentPage,"page-size":_vm.pageTotal,"layout":"prev, pager, next, jumper","total":_vm.totalPerple},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }