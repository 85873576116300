import { request, noTimeOutReq } from '@/utils/request.js'

//查询历史批次集合
export function getHistoryBatchListApi (examId, sysOrgExamPlaceId, data) {
  return request({
    url: `/call/batch/get/history/sessionList/${examId}/${sysOrgExamPlaceId}`,
    method: 'post',
    data: data
  })
}

//历史批次信息
export function getHistoryBatchSessionInfoApi (examId, sysOrgExamPlaceId, examSession, data) {
  return request({
    url: `/call/batch/get/history/sessionInfo/${examId}/${sysOrgExamPlaceId}/${examSession}`,
    method: 'post',
    data: data
  })
}





