<template>
  <div id="header">
    <el-card shadow="always">
      <div>
        <el-button v-throttle type="primary" size="medium" @click="goBack"
          >返回</el-button
        >
      </div>
      <div id="exam-box">{{ urlParam.examName }}</div>
      <div id="place-box">
        考点：{{ urlParam.sysOrgExamPlaceName }}
        <span
          v-if="
            currBatchSessionInfo.callBatchPlatformHistorySessionExamineeList &&
            currBatchSessionInfo.callBatchPlatformHistorySessionExamineeList
              .length > 0
          "
          >批次：{{
            currBatchSessionInfo.examSession > 9
              ? currBatchSessionInfo.examSession
              : '0' + currBatchSessionInfo.examSession
          }}</span
        >
      </div>
      <!-- <div id="examination-room-box">
        考场：{{
          urlParam.randomTimingType == 1
            ? currBatchSessionInfo.sysOrgExamPlaceRoomName
            : centralizeExamPlaceRoomName
        }}
      </div> -->
      <div style="margin-top: 10px; line-height: 36px">
        <el-row>
          <span>
            统计：当前已完成考试{{ censusInfo.haveExamExamineeCount }}人，
            未完成考试{{ censusInfo.notExamExamineeCount }}人， 共{{
              censusInfo.examExamineeCount
            }}人
          </span>
          <span
            v-if="currBatchSessionInfo.sessionFinishTime"
            style="margin-left: 30px"
            >确定批次时间：{{
              formatDateTime(
                new Date(currBatchSessionInfo.sessionFinishTime),
                'yyyy-MM-dd hh:mm:ss'
              )
            }}</span
          >
        </el-row>
        <el-row style="margin-top: 10px">
          <el-table
            v-loading="loading"
            stripe
            border
            ref="userTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#DFE6EC',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column
              prop="index"
              label="序号"
              width="60"
            ></el-table-column>
            <el-table-column
              prop="examineeCode"
              label="考生编号"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="examineeName"
              label="考生姓名"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="examineeSchoolName"
              label="考生学校"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="examineeSchoolClassName"
              label="考生班级"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="sysOrgExamPlaceRoomName"
              label="所在考场"
              show-overflow-tooltip
              v-if="urlParam.randomTimingType == 1"
            ></el-table-column>
            <el-table-column
              prop="sysOrgExamPlaceRoomNameList"
              label="所在考场"
              show-overflow-tooltip
              :formatter="customFormatter"
              v-if="urlParam.randomTimingType == 2"
            ></el-table-column>
            <el-table-column
              v-if="randomTimingDrawType == 1"
              prop="seatCode"
              label="座位号"
              show-overflow-tooltip
            ></el-table-column>
          </el-table>
          <div id="pagination-box">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="pageTotal"
              layout="prev, pager, next, jumper"
              :total="totalPerple"
            ></el-pagination>
          </div>
        </el-row>
      </div>
    </el-card>
  </div>
</template>
<script>
import {
  getHistoryBatchListApi,
  getHistoryBatchSessionInfoApi,
} from '@/api/rollCall/historyBatch.js'
import { getExamineeCountApi } from '@/api/rollCall/rollCall.js'
import { isEmpty } from '@/utils/util'
// import cryptoAES from '@/utils/crypto.js'
export default {
  name: 'historyBatch',
  data() {
    return {
      loading: false,
      currentPage: 1,
      totalPerple: 0,
      pageTotal: 0,
      urlParam: {
        examId: 0,
        examSession: 0,
        examPlaceId: 0,
        randomTimingType: 0,
        examSubjectId: 0,
        sysOrgExamPlaceRoomId: 0,
        examName: '',
        sysOrgExamPlaceName: '',
        chooseFlag: 0,
      },
      //统计信息
      censusInfo: {},
      jumpPage: '',
      chooseFlag: 1,
      tableData: [],
      defaultSession: 1,
      allBatchSession: [],
      currBatchSessionInfo: {},
      //集中点名显示考场
      centralizeExamPlaceRoomName: '',
      // 抽签方式（1报到即抽签 2报到后抽签）
      randomTimingDrawType: 1,
    }
  },
  mounted() {
    this.randomTimingDrawType = Number(this.$route.query.randomTimingDrawType)
    this.renderUrlParam()
  },
  methods: {
    renderUrlParam() {
      this.urlParam.examId = 0
      this.urlParam.examId = Number(this.$route.query.examId)
      this.urlParam.examSession = 0
      this.urlParam.examSession = Number(this.$route.query.examSession)
      this.urlParam.examPlaceId = 0
      this.urlParam.examPlaceId = Number(this.$route.query.examPlaceId)
      this.urlParam.randomTimingType = 0
      this.urlParam.randomTimingType = Number(
        this.$route.query.randomTimingType
      )
      if (this.urlParam.randomTimingType == 1) {
        this.urlParam.examSubjectId = 0
        this.urlParam.examSubjectId = Number(this.$route.query.examSubjectId)
        this.urlParam.sysOrgExamPlaceRoomId = 0
        this.urlParam.sysOrgExamPlaceRoomId = Number(
          this.$route.query.sysOrgExamPlaceRoomId
        )
        this.urlParam.chooseFlag = 0
        this.urlParam.chooseFlag = Number(this.$route.query.chooseFlag)
      }
      this.urlParam.examName = ''
      this.urlParam.examName = this.$route.query.examName
      this.urlParam.sysOrgExamPlaceName = ''
      this.urlParam.sysOrgExamPlaceName = this.$route.query.sysOrgExamPlaceName

      //查询考试考生汇总信息
      this.getExamineeCount()
      //查询历史批次
      this.getHistoryBatchList()
      //历史批次信息
      this.getHistoryBatchSessionInfo()
    },
    //查询考试考生汇总信息
    getExamineeCount() {
      getExamineeCountApi(this.urlParam.examId).then((res) => {
        if (res.success) {
          this.censusInfo = {}
          this.censusInfo = res.data
        } else {
          this.msgError('获取统计信息失败')
        }
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      this.getHistoryBatchSessionInfo()
    },
    //查询历史批次
    getHistoryBatchList() {
      let returnData = {
        randomTimingType: this.urlParam.randomTimingType,
      }
      if (this.urlParam.randomTimingType == 1) {
        returnData.sysOrgExamPlaceRoomId = this.urlParam.sysOrgExamPlaceRoomId
      }
      getHistoryBatchListApi(
        this.urlParam.examId,
        this.urlParam.examPlaceId,
        returnData
      ).then((res) => {
        if (res.success) {
          this.allBatchSession = []
          this.allBatchSession = res.data
          this.totalPerple = res.total
          this.pageTotal = res.pageTotal
          if (this.allBatchSession.length <= 0) {
            this.globalMessageBox(
              '检测到您还没有完成的批次，是否前往录入?',
              '系统提示',
              '确定',
              '取消',
              'warning'
            )
              .then(() => {
                switch (this.urlParam.randomTimingType) {
                  case 1:
                    this.$router.push({
                      path: '/rollCall/separateRollCall',
                      query: {
                        examId: this.urlParam.examId,
                        examSession: this.urlParam.examSession,
                        examPlaceId: this.urlParam.examPlaceId,
                        randomTimingType: this.urlParam.randomTimingType,
                        examSubjectId: this.urlParam.examSubjectId,
                        sysOrgExamPlaceRoomId:
                          this.urlParam.sysOrgExamPlaceRoomId,
                        chooseFlag: this.urlParam.chooseFlag,
                        randomTimingDrawType: this.randomTimingDrawType,
                      },
                    })
                    break
                  case 2:
                    this.$router.push({
                      path: '/rollCall/centralizeRollCall',
                      query: {
                        examId: this.urlParam.examId,
                        examPlaceId: this.urlParam.examPlaceId,
                        randomTimingType: this.urlParam.randomTimingType,
                        randomTimingDrawType: this.randomTimingDrawType,
                        sysOrgExamPlaceRoomId:
                          this.urlParam.sysOrgExamPlaceRoomId,
                      },
                    })
                    break
                }
              })
              .catch(() => {})
          }
        }
      })
    },
    //历史批次信息
    getHistoryBatchSessionInfo() {
      let returnData = {
        randomTimingType: this.urlParam.randomTimingType,
      }
      if (this.urlParam.randomTimingType == 1) {
        returnData.sysOrgExamPlaceRoomId = this.urlParam.sysOrgExamPlaceRoomId
      }
      getHistoryBatchSessionInfoApi(
        this.urlParam.examId,
        this.urlParam.examPlaceId,
        this.currentPage,
        returnData
      ).then((res) => {
        if (res.success) {
          this.currBatchSessionInfo = {}
          this.currBatchSessionInfo = res.data
          this.tableData = []
          this.tableData = res.data.callBatchPlatformHistorySessionExamineeList
          this.tableData.forEach((item, index) => {
            if (isEmpty(item)) {
              return
            }
            this.$set(item, 'index', index + 1)
          })
          this.centralizeExamPlaceRoomName = ''
          this.tableData[0].sysOrgExamPlaceRoomNameList.forEach(
            (item, index) => {
              if (isEmpty(item)) {
                return
              }
              this.centralizeExamPlaceRoomName += item
              if (
                index <
                this.tableData[0].sysOrgExamPlaceRoomNameList.length - 1
              ) {
                this.centralizeExamPlaceRoomName += ','
              }
            }
          )
        }
      })
    },
    // //选择考试批次
    // chooseBatchSession (session) {
    //   session = Number(session);
    //   this.chooseFlag = session;
    //   this.defaultSession = session;
    //   this.getHistoryBatchSessionInfo();
    // },
    // //上一页
    // customPrevSession () {
    //   if (this.defaultSession == 1) {
    //     this.msgWarning('已经是第一批了');
    //     return;
    //   }
    //   --this.chooseFlag;
    //   --this.defaultSession
    //   this.getHistoryBatchSessionInfo();
    // },
    // //下一页
    // customNextSession () {
    //   if (this.defaultSession == this.allBatchSession.length) {
    //     this.msgWarning('已经是最后一批了');
    //     return;
    //   }
    //   ++this.chooseFlag;
    //   ++this.defaultSession;
    //   this.getHistoryBatchSessionInfo();
    // },
    // jumpInput () {
    //   if (this.jumpPage < 1) {
    //     this.jumpPage = 1
    //   }
    //   if (this.jumpPage > this.allBatchSession.length) {
    //     this.jumpPage = this.allBatchSession.length;
    //   }
    // },
    // //跳至n页
    // customJumpSession () {
    //   if (isEmpty(this.jumpPage)) {
    //     return;
    //   }
    //   this.chooseFlag = this.jumpPage;
    //   this.defaultSession = this.jumpPage;
    //   this.getHistoryBatchSessionInfo();
    // },
    goBack() {
      switch (this.urlParam.randomTimingType) {
        case 1:
          this.$router.push({
            path: '/rollCall/separateRollCall',
            query: {
              examId: this.urlParam.examId,
              examSession: this.urlParam.examSession,
              examPlaceId: this.urlParam.examPlaceId,
              randomTimingType: this.urlParam.randomTimingType,
              examSubjectId: this.urlParam.examSubjectId,
              sysOrgExamPlaceRoomId: this.urlParam.sysOrgExamPlaceRoomId,
              chooseFlag: this.urlParam.chooseFlag,
              randomTimingDrawType: this.randomTimingDrawType,
            },
          })
          break
        case 2:
          this.$router.push({
            path: '/rollCall/centralizeRollCall',
            query: {
              examId: this.urlParam.examId,
              examPlaceId: this.urlParam.examPlaceId,
              randomTimingType: this.urlParam.randomTimingType,
              randomTimingDrawType: this.randomTimingDrawType,
              sysOrgExamPlaceRoomId: this.urlParam.sysOrgExamPlaceRoomId,
            },
          })
          break
      }
    },
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return
      }
      switch (column.property) {
        case 'sysOrgExamPlaceRoomNameList':
          this.$nextTick(() => {})
          let appendReturn = ''
          row.sysOrgExamPlaceRoomNameList.forEach((item, index) => {
            if (isEmpty(item)) {
              return
            }
            appendReturn += item
            if (index < row.sysOrgExamPlaceRoomNameList.length - 1) {
              appendReturn += ','
            }
          })
          return appendReturn
      }
    },
    /* 时间格式化 */
    formatDateTime(date, fmt) {
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
      }
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + ''
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          )
        }
      }
      return fmt
    },
    padLeftZero(str) {
      return ('00' + str).substr(str.length)
    },
  },
}
</script>
<style lang="scss" scoped>
#header {
  padding: 0 10px;
  margin-top: 10px;
}

::v-deep .el-card__body {
  padding: 15px;
}

#exam-box {
  font-weight: bold;
  font-size: 20px;
}

#place-box,
#examination-room-box {
  margin-top: 10px;
  font-size: 16px;
}

#exam-box,
#place-box,
#examination-room-box {
  text-align: center;
}

#pagination-box {
  margin-top: 15px;
  text-align: center;
}

::v-deep .el-pagination > slot:nth-child(2),
::v-deep .el-pagination > slot:nth-child(3),
::v-deep .el-pagination > slot:last-child {
  display: none;
}

#prevBtn,
#nextBtn,
#jumpBtn {
  color: #fff;
  background-color: #409eff;
  display: inline-block;
  // height: 0;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  font-size: 12px;
  border-radius: 3px;
}

::v-deep #custom-pagination > span > span {
  margin: 0 5px;
  background-color: #f4f4f5;
  color: #606266;
  min-width: 30px;
  border-radius: 2px;
  cursor: pointer;
}

#custom-pagination .active {
  background-color: #409eff;
  color: #fff;
}

::v-deep .el-input__inner {
  height: 28px;
}

/* .el-table__row > td:last-child {
  background-color: #1890ff;
  opacity: 0.6;
  color: white;
} */
</style>
